app-root {
  display: block;
  height: 100dvh;
  width: 100%;
  background-color: rgb(33, 33, 33);
}

#bootstrap-overrides .custom-height {
  height: calc(-24px + 100dvh - 66px) !important;
  overflow-y: auto;
}

html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  background-color: rgb(33, 33, 33);
  box-sizing: border-box;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(33, 33, 33);
  width: 100%;
  height: 100dvh;
  overflow: hidden;
  margin: 0 !important;
}

main {
  background-color: rgb(33, 33, 33);
}

body.dialog-active {
  align-items: flex-start;
}

* {
  margin: 0;
  box-sizing: border-box;
}

/* ------------------------------ */
/* ------ SCROLL BAR STUFF ------ */
/* ------------------------------ */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background: #333;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
  border: 3px solid #333;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

* {
  scrollbar-color: #888 #212121;
}

/* Line reveal animation */
@keyframes lineReveal {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.line-reveal {
  opacity: 0;
  animation: lineReveal 1s ease-out forwards;
}

.as-split-gutter {
  background-color: transparent !important;
}

.ng2-pdf-viewer-container {
  overflow-x: hidden !important;
}
